
import { defineComponent, onBeforeMount, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'notEnabled',
  setup (props, ctx) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()

    onBeforeMount(() => {
      const { title } = route.query
      data.title = title || '项目未启动'
    })

    const data: any = reactive({
      title: ''
    })

    return {
      ...toRefs(data)
    }
  }
})
